// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Logo } from "./logo"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `white`,
      marginBottom: `10px`,
      marginTop: "10px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "300px", maxHeight: "56px" }}>
        <Logo />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
